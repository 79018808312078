import React, {useState} from 'react';
import {LandingStructure} from "../../../app/API/landing-structure";
import {NavItem} from "./nav-item";

interface NavbarProps {
    children?: string | JSX.Element | JSX.Element[]
}

export const Navbar: React.FC<NavbarProps> = () => {
    const [isActive, setIsActive] = useState<boolean>(false);
    return (
        <>
            <div className={' bg-slate-200 w-full py-4 bg-neutral flex flex-col justify-between items-center gap-6 rounded-b-2xl lg:rounded-none shadow-lg lg:shadow-none'}>

                <div
                    onClick={() => {
                        setIsActive(!isActive)
                    }}
                    className={(isActive ? '' : ' hidden ') + ' w-screen h-screen fixed top-0 left-0 z-10'}
                ></div>

                <ul className={(isActive ? ' lg:flex  ' : ' hidden lg:flex  ') + " z-40 flex flex-col lg:flex-row container mx-auto w-full items-center justify-between gap-2 lg:gap-0 "}>
                    {LandingStructure.map(({component, anchor, name, addToNavbar}, index) => {
                            if (addToNavbar) {
                                return <NavItem
                                    key={index}
                                    anchor={anchor}
                                    name={name}
                                />
                            }
                        }
                    )}
                </ul>

                {
                    !isActive
                        ?
                        <button
                            onClick={() => {
                                setIsActive(!isActive)
                            }}
                            className={'z-40 lg:hidden w-full flex justify-center'}
                        >
                            <svg
                                className={'w-8 h-auto rounded-full'}
                                xmlns="http://www.w3.org/2000/svg"
                                height="48"
                                viewBox="0 -960 960 960"
                                width="48"
                            >
                                <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"/>
                            </svg>
                        </button>
                        :
                        <button
                            onClick={() => {
                                setIsActive(!isActive)
                            }}
                            className={'z-40 lg:hidden w-full flex justify-center'}
                        >
                            <svg
                                className={'w-10 h-auto bg-white p-2 rounded-full'}
                                xmlns="http://www.w3.org/2000/svg"
                                height="48"
                                viewBox="0 -960 960 960"
                                width="48"
                            >
                                <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/>
                            </svg>
                        </button>
                }

            </div>
        </>
    );
};