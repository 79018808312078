import React from 'react';

interface NavItemProps {
    anchor: string
    name: string
}

export const NavItem: React.FC<NavItemProps> = ({anchor,name}) => {
    return (
        <>
            <li className={'hover:cursor-pointer hover:scale-110 text-lg lg:text-sm'}>
                <a className={'py-1 px-2'} href={'/#' + anchor}>{name}</a>
            </li>
        </>
    );
};